import React from "react";
import Index from "../pages";
import Form from "../pages/form";
import { IRoutes } from "../types/App/Types";

const Routes: IRoutes[] = [
  {
    path: "/",
    key: "INDEX",
    element: <Index />,
  },
  {
    path: "/form/:id",
    key: "Form",
    element: <Form />,
  },
];

export default Routes;

// function RoutesWithSubRoutes(route: IRoutes) {
//   return (
//     <Route
//       path={route.path}
//       render={(props:any) => <route.component {...props} routes={route.routes} />}
//     />
//   );
// }

// export function RenderRoutes({ routes }: { routes: IRoutes[] }) {
//   return (
//     <Routes>
//       {routes.map((route, i) => {
//         return <RoutesWithSubRoutes {...route} />;
//       })}
//       <Route element={<h1>No Content Here!</h1>} />
//     </Routes>
//   );
// }
