import React from "react";
import { Control, Path, UseFormRegister } from "react-hook-form";
import { Field } from "../../Services";
import { FieldTypes } from "../../types/App/Types";
import { PrimaryCheckBox } from "./PrimaryCheckBox";
import { PrimaryDate } from "./PrimaryDateInput";
import { PrimaryInput } from "./PrimaryInput";
import { PrimaryRadioButton } from "./PrimaryRadio";
import { PrimarySelect } from "./PrimarySelect";
import { PrimaryTextarea } from "./PrimaryTextArea";

interface InputProps<TFormValues extends Record<string, unknown>> {
  field: Field;
  register: UseFormRegister<TFormValues>;
  errors: { [x: string]: any };
  control: Control<TFormValues>;
}

export default function InputField<TFormValues extends Record<string, any>>({
  field,
  register,
  errors,
  control,
}: InputProps<TFormValues>): JSX.Element {
  console.log(field);
  switch (field.fieldTypeId) {
    case FieldTypes.TEXT:
      return (
        <PrimaryInput
          name={field.name as unknown as Path<TFormValues>}
          label={field.label as string}
          placeholder={field.placeholder as string}
          register={register}
          error={errors[field.name as string]}
          defaultValue={""}
          required={field.required as boolean}
          type={field.fieldType?.name?.toLocaleLowerCase()}
          tooltip={field.toolTip as string}
        />
      );
    case FieldTypes.SELECT:
      return (
        <PrimarySelect
          error={errors[field.name as string]}
          name={field.name as unknown as Path<TFormValues>}
          label={field.label as string}
          placeholder={field.placeholder as string}
          register={register}
          defaultValue={""}
          required={field.required as boolean}
          tooltip={field.toolTip as string}
          options={field.options?.split(",") as string[]}
        />
      );
    case FieldTypes.TEXTAREA:
      return (
        <PrimaryTextarea
          name={field.name as unknown as Path<TFormValues>}
          label={field.label as string}
          placeholder={field.placeholder as string}
          register={register}
          error={errors[field.name as string]}
          defaultValue={""}
          required={field.required as boolean}
          type={field.fieldType?.name?.toLocaleLowerCase()}
          tooltip={field.toolTip as string}
        />
      );
    case FieldTypes.DATETIME:
      return (
        <PrimaryInput
          name={field.name as unknown as Path<TFormValues>}
          label={field.label as string}
          placeholder={field.placeholder as string}
          register={register}
          error={errors[field.name as string]}
          defaultValue={""}
          required={field.required as boolean}
          type={field.fieldType?.name?.toLocaleLowerCase()}
          tooltip={field.toolTip as string}
        />
      );
    case FieldTypes.TIME:
      return (
        <PrimaryInput
          name={field.name as unknown as Path<TFormValues>}
          label={field.label as string}
          placeholder={field.placeholder as string}
          register={register}
          error={errors[field.name as string]}
          defaultValue={""}
          required={field.required as boolean}
          type={field.fieldType?.name?.toLocaleLowerCase()}
          tooltip={field.toolTip as string}
        />
      );
    case FieldTypes.DATE:
      return (
        <PrimaryInput
          name={field.name as unknown as Path<TFormValues>}
          label={field.label as string}
          placeholder={field.placeholder as string}
          register={register}
          error={errors[field.name as string]}
          defaultValue={""}
          required={field.required as boolean}
          type={field.fieldType?.name?.toLocaleLowerCase()}
          tooltip={field.toolTip as string}
        />
      );
    case FieldTypes.RADIO:
      return (
        <PrimaryRadioButton
          name={field.name as unknown as Path<TFormValues>}
          label={field.label as string}
          error={errors[field.name as string]}
          defaultValue={""}
          required={field.required as boolean}
          tooltip={field.toolTip as string}
          control={control}
          register={register}
          options={field.options?.split(",") as string[]}
        />
      );
    case FieldTypes.CHECKBOX:
      return (
        <PrimaryCheckBox
          name={field.name as unknown as Path<TFormValues>}
          label={field.label as string}
          error={errors[field.name as string]}
          defaultValue={""}
          required={field.required as boolean}
          tooltip={field.toolTip as string}
          // @ts-ignore
          control={control}
          register={register}
          options={field.options?.split(",") as string[]}
        />
      );
    default:
      return (
        <PrimaryInput
          name={field.name as unknown as Path<TFormValues>}
          label={field.label as string}
          placeholder={field.placeholder as string}
          register={register}
          error={errors[field.name as string]}
          defaultValue={""}
          required={field.required as boolean}
          type={field.fieldType?.name?.toLocaleLowerCase()}
          tooltip={field.toolTip as string}
        />
      );
  }
  return <></>;
}
