import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box } from '@chakra-ui/react';
import React, { FC } from 'react'
import { FiMinus, FiPlus } from 'react-icons/fi';

interface ICreateStepAccordion {
    name: string;
    lighter?: boolean;
  }
export const  CreateStepAccordion: FC<ICreateStepAccordion> = ((
    { name, children, lighter }) => (
    <Accordion defaultIndex={[0]} allowMultiple>
      <AccordionItem border={'none'}>
        {({ isExpanded }) => (
          <>
            <h2>
              <AccordionButton
                h={'14'}
                borderRadius={'md'}
                bgColor={lighter ? 'silver': 'blackAlpha.100'}
                color={lighter ? 'black': 'blackAlpha.700'}
                _expanded={!lighter ? { bg: 'blackAlpha.900', color: 'white' } : { bg: 'white', color: 'blackAlpha.900', border: '1px solid black' }}
                _hover={{
                  bg: 'blackAlpha.100',
                  color: 'blackAlpha.700',
                }}
              >
                <Box
                  flex="1"
                  textAlign="left"
                  fontSize={'md'}
                  fontWeight={'semibold'}
                  lineHeight={'6'}
                >
                  {name}
                </Box>
                {isExpanded ? <FiMinus /> : <FiPlus />}
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>{children}</AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  ));
