import React, { useState } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  Textarea,
} from "@chakra-ui/react";
import { PrimaryInput } from "../Components/CustomInputs/PrimaryInput";
import { FieldInput, FormInput, FormStepInput } from "../Services";
import { FieldError, FieldErrors, useForm } from "react-hook-form";
import { FiShoppingBag } from "react-icons/fi";
import { Step } from "../Components/Step";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const defaultField: FieldInput = {
  label: "",
  placeholder: "",
  required: false,
  options: "",
  toolTip: "",
  fieldTypeId: 1,
  templateId: undefined,
};

const schema = yup.object().shape({
  title: yup.string().required("Title is required"),
  description: yup.string().required("Description is required"),
  steps: yup
    .array()
    .of(
      yup.object().shape({
        index: yup.number().required("index is required"),
        fields: yup.array().of(
          yup.object().shape({
            label: yup.string().required("Label is required"),
            placeholder: yup.string().required("Placeholder is required"),
            required: yup.boolean().required("Required is required"),
            options: yup.string().required("Options is required"),
            toolTip: yup.string().required("Tooltip is required"),
            fieldTypeId: yup.number().required("Field Type is required"),
            templateId: yup.number().required("Template is required"),
          })
        ),
      })
    )
    .required("Steps are required"),
});

export default function Index() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    watch,
  } = useForm<FormInput>({ mode: "all", resolver: yupResolver(schema) });

  const addStep = () => {
    console.log(errors);
    const steps = getValues("steps") ?? [];
    console.log(steps);

    setValue("steps", [
      ...(steps as FormStepInput[]),
      { fields: [defaultField], index: steps.length + 1 },
    ]);
  };

  const removeStep = (index: number) => {
    const steps = getValues("steps") ?? [];
    const newSteps = [...(steps as FormStepInput[])];
    newSteps.splice(index, 1);
    setValue("steps", newSteps);
  };

  return (
    <>
      <Flex direction={"column"} w={"760px"} mx={"auto"} my={"6"}>
        <Flex
          justifyContent={"center"}
          lineHeight={"8"}
          fontWeight={"semibold"}
          fontSize={"2xl"}
        >
          Create New Form
        </Flex>
        <Flex
          mt={"2"}
          mb={"4"}
          justifyContent={"center"}
          lineHeight={"6"}
          color={"blackAlpha.500"}
          fontSize={"md"}
        >
          Make a new form for your users to fill out.
        </Flex>
        <Flex
          direction={"column"}
          backgroundColor={"white"}
          px={"8"}
          borderRadius={"4"}
        >
          <Flex
            lineHeight={"7"}
            fontWeight={"semibold"}
            fontSize={"xl"}
            mt={"12"}
            mb={"9"}
          >
            Form Details
          </Flex>
          <Flex direction={"column"}>
            <SimpleGrid columns={2} spacingX={14} spacingY={6}>
              <PrimaryInput<FormInput>
                name={"title"}
                label={"Form Name"}
                placeholder={"Enter Form Name"}
                register={register}
                error={errors.title}
                defaultValue={""}
              />

              <PrimaryInput<FormInput>
                name={"description"}
                label={"Form Description"}
                placeholder={"Enter Form Description"}
                register={register}
                error={errors.description}
                defaultValue={""}
              />
            </SimpleGrid>
          </Flex>
          <Box h={"1px"} w={"100%"} bgColor={"gray.100"} my={"8"} />
          <Flex direction={"column"}>
            <Flex justifyContent={"space-between"}>
              <Flex
                fontSize={"xl"}
                fontWeight={"semibold"}
                color={"blackAlpha.800"}
              >
                Steps
              </Flex>
              <Button variant="link" onClick={() => addStep()}>
                <Flex color={"blue.600"}>
                  <FiShoppingBag />
                </Flex>
                <Flex
                  fontSize={"xs"}
                  fontWeight={"semibold"}
                  color={"blue.600"}
                  ml={"2"}
                >
                  Add new step
                </Flex>
              </Button>
            </Flex>
          </Flex>
          <Flex direction={"column"}>
            {watch("steps")?.map((item, index) => (
              <Step
                name={`Step ${index + 1}`}
                step={item}
                index={index}
                key={`step-${index}`}
                onRemove={() => removeStep(index)}
                //@ts-ignore
                errors={errors?.steps?.[index]}
                register={register}
              />
            ))}
          </Flex>
          <Box h={"1px"} w={"100%"} bgColor={"gray.100"} my={"8"} />
          <Flex
            direction={"row"}
            justifyContent={"space-between"}
            mb={"9"}
            mt={"10"}
          >
            <Button
              bgColor={"blackAlpha.200"}
              color={"blackAlpha.700"}
              lineHeight={"5"}
              fontSize={"md"}
              fontWeight={"medium"}
            >
              Cancel
            </Button>
            <Button
              bgColor={"blue.600"}
              color={"white"}
              lineHeight={"5"}
              fontSize={"md"}
              fontWeight={"medium"}
            >
              Submit
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
