import {
  FormControl,
  FormLabel,
  GridItem,
  HStack,
  Text,
  Textarea,
  Tooltip,
} from "@chakra-ui/react";
import { FieldError, UseFormRegister, Path } from "react-hook-form";
import { AiFillQuestionCircle } from "react-icons/ai";

interface FormInputProps<TFormValues extends Record<string, unknown>> {
  name: Path<TFormValues>;
  placeholder?: string;
  label?: string;
  register: UseFormRegister<TFormValues>;
  error: FieldError | undefined;
  type?: string;
  required?: boolean;
  disableLabel?: boolean;
  validate?: any;
  icon?: any;
  variant?: string;
  borderColor?: string;
  borderRadius?: string;
  placeholderColor?: string;
  defaultValue: string | number | undefined;
  format?: string;
  value?: string | number | undefined;
  testId?: string;
  w?: string;
  h?: string;
  padding?: string;
  tooltip?: string;
}

export const PrimaryTextarea = <TFormValues extends Record<string, any>>({
  name,
  required = false,
  type = "text",
  label = "",
  register,
  validate = {},
  error,
  disableLabel = false,
  placeholder = "",
  variant = "outline",
  borderColor = "gray.300",
  borderRadius = "md",
  placeholderColor = "gray.300",
  defaultValue,
  format,
  value,
  icon,
  testId,
  w,
  h,
  padding,
  tooltip,
}: FormInputProps<TFormValues>) => {
  return (
    <GridItem colSpan={2}>
      <FormControl isInvalid={!!error}>
        <HStack justifyContent={"space-between"}>
          <FormLabel color="brandBlack.100" htmlFor={name}>
            {label}
          </FormLabel>
          {tooltip && (
            <Tooltip hasArrow label={tooltip} fontSize="sm">
              <span style={{ display: "block" }}>
                <AiFillQuestionCircle />
              </span>
            </Tooltip>
          )}
        </HStack>
        <Textarea
          placeholder={placeholder}
          {...register(name, { required, ...validate })}
          defaultValue={defaultValue}
          isDisabled={disableLabel}
          padding={padding}
          size="sm"
          h={h}
          resize="none"
          focusBorderColor="none"
          bgColor="rgba(25,25,25,.03)"
          borderColor="transparent"
          borderRadius="5px"
          // boxShadow="0px 0px 9px rgba(0, 127, 130, 0.37)"
        />
      </FormControl>
      <Text fontSize=".7rem" color="red">
        {(error?.type === "required" && `${label} is required`) ||
          error?.message}
      </Text>
    </GridItem>
  );
};
