import {
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  InputRightElement,
  Center,
  Button,
  FormErrorMessage,
  Tooltip,
  HStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import {
  FieldError,
  UseFormRegister,
  RegisterOptions,
  Path,
} from "react-hook-form";
import {
  AiFillQuestionCircle,
  AiOutlineEye,
  AiOutlineEyeInvisible,
} from "react-icons/ai";
import { FiEyeOff, FiEye } from "react-icons/fi";

interface FormInputProps<TFormValues extends Record<string, unknown>> {
  name: Path<TFormValues>;
  placeholder?: string;
  label?: string;
  register: UseFormRegister<TFormValues>;
  error: FieldError | undefined;
  type?: string;
  required?: boolean;
  disableLabel?: boolean;
  validate?: any;
  icon?: boolean;
  variant?: string;
  borderColor?: string;
  borderRadius?: string;
  placeholderColor?: string;
  defaultValue: string | number | undefined;
  format?: string;
  value?: string | number | undefined;
  testId?: string;
  tooltip?: string;
}

export const PrimaryInput = <TFormValues extends Record<string, any>>({
  name,
  type = "text",
  label = "",
  register,
  error,
  placeholder = "",
  required = false,
  validate = undefined,
  variant = "outline",
  testId,
  icon,
  tooltip = '',
}: FormInputProps<TFormValues>): JSX.Element => {
  const [showPassword, setShowPassword] = React.useState(false);
  return (
    <FormControl
      isInvalid={error as unknown as boolean}
      variant={variant ? variant : "outline"}
    >
      <HStack justifyContent={'space-between'}>
        <FormLabel color="brandBlack.100" htmlFor={name}>
          {label}
        </FormLabel>
        {tooltip && (<Tooltip hasArrow label={tooltip} fontSize="sm">
          <span style={{ display: "block" }}>
            <AiFillQuestionCircle />
          </span>
        </Tooltip>)}
      </HStack>
      <InputGroup>
        <Input
          // autoComplete="off"
          _autofill={{ WebkitBoxShadow: "0 0 0 30px white inset" }}
          id={name}
          placeholder={placeholder}
          _placeholder={{ color: "#9C9898" }}
          focusBorderColor={"brandGreen.100"}
          errorBorderColor={"red.500"}
          {...register(name, { required, ...validate })}
          size={"lg"}
          isInvalid={error as unknown as boolean}
          type={showPassword ? "text" : type}
          name={name}
          data-testid={testId}
          disabled={false}
        />
        {icon && (
          <InputRightElement height={"100%"}>
            <Center>
              <Button
                padding={"0"}
                _hover={{ bgColor: "transparent" }}
                _focus={{ border: "none" }}
                bgColor={"transparent"}
                onClick={() => setShowPassword(!showPassword)}
                cursor={type ? "pointer" : "text"}
              >
                {type == "password" ? (
                  showPassword ? (
                    <FiEyeOff size={"24px"} />
                  ) : (
                    <FiEye size={"24px"} />
                  )
                ) : (
                  icon
                )}
              </Button>
            </Center>
          </InputRightElement>
        )}
      </InputGroup>
      <FormErrorMessage>
        {error?.type == "required" ? `${label} is required` : error?.message}
      </FormErrorMessage>
    </FormControl>
  );
};
