import {
  FormControl,
  FormLabel,
  RadioGroup,
  Stack,
  HStack,
  Radio,
  Tooltip,
} from "@chakra-ui/react";
import {
  Controller,
  UseFormRegister,
  Path,
  FieldError,
  Control,
} from "react-hook-form";
import { AiFillQuestionCircle } from "react-icons/ai";

// import {} from '@chakra-ui/core';

interface FormInputProps<TFormValues extends Record<string, unknown>> {
  name: Path<TFormValues>;
  required?: boolean;
  disableLabel?: boolean;
  validate?: any;
  label?: string;
  register: UseFormRegister<TFormValues>;
  defaultValue: string | number | undefined;
  error: FieldError | undefined;
  control: Control<TFormValues>;
  radios?: any;
  tooltip?: string;
  options: string[];
}

export const PrimaryRadioButton = <TFormValues extends Record<string, any>>({
  name,
  required = false,
  label = "",
  register,
  validate = {},
  error,
  defaultValue,
  control,
  radios,
  tooltip,
  options,
}: FormInputProps<TFormValues>) => {
  return (
    <FormControl>
      <HStack justifyContent={'space-between'}>
        <FormLabel color="brandBlack.100" htmlFor={name}>
          {label}
        </FormLabel>
        {tooltip && (<Tooltip hasArrow label={tooltip} fontSize="sm">
          <span style={{ display: "block" }}>
            <AiFillQuestionCircle />
          </span>
        </Tooltip>)}
      </HStack>
      <Controller
        render={({ field }) => (
          <HStack justify="space-between" spacing={6}>
            <RadioGroup aria-label={label} {...field} w="full">
              <Stack
                direction={["column", "row"]}
                w="full"
                align="flex-start"
                spacing={["1.25rem", "1.5rem"]}
              >
                {options.map((option, index) => {
                  return (
                    <Radio
                      key={index}
                      name={name}
                      value={option}
                      aria-label={option}
                      aria-describedby={tooltip}
                    >
                      {option}
                    </Radio>
                  );
                })}
              </Stack>
            </RadioGroup>
          </HStack>
        )}
        name={name}
        control={control}
      />
    </FormControl>
  );
};
