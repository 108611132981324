import { Flex, SimpleGrid, FormControl, FormLabel, Input, FormErrorMessage, Textarea, Button } from '@chakra-ui/react'
import React, { FC } from 'react'
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { FiTrash2 } from 'react-icons/fi'
import { FormInput, FormStepInput } from '../Services';
import { CreateStepAccordion } from './CreateStepAccordion'
import { PrimaryInput } from './CustomInputs/PrimaryInput';
import { StepField } from './StepField';

interface IStep {
    name: string;
    errors?: FieldErrors<FormStepInput>;
    index: number;
    onRemove: () => void;
    step: FormStepInput;
    register: UseFormRegister<FormInput>
}

export const Step: FC<IStep> = (
    ({ name, step, index, onRemove,errors,register }) =>  (
    <Flex direction={'column'} mt={'8'}>
    <CreateStepAccordion name={name}>
      <Flex direction={'column'}>
        
        {step?.fields?.map((field,fieldIndex) => (
        <StepField 
            name={`Field ${fieldIndex + 1}`}
            //@ts-ignore
            errors={errors?.fields?.[fieldIndex]}
            index={index}
            onRemove={onRemove}
            step={step}
            register={register}
            fieldIndex={0}
            field={field}
        />
        ))}
      </Flex>
      <Flex justifyContent={'end'} mt={'6'}>
            <Flex>
              <Button variant="link" onClick={onRemove}>
                <Flex color={'red'} fontSize={24}>
                  <FiTrash2 />
                </Flex>
              </Button>
            </Flex>
          </Flex>
    </CreateStepAccordion>
  </Flex>
  ))

