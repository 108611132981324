import {
  Flex,
  Box,
  Button,
  Center,
  Heading,
  Text,
  SimpleGrid,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FormDataInput, FormService, FormView } from "../Services";
import { WarningTwoIcon } from "@chakra-ui/icons";
import { useForm, useFormState } from "react-hook-form";
import { PrimaryInput } from "../Components/CustomInputs/PrimaryInput";
import InputField from "../Components/CustomInputs/InputField";

export default function Form() {
  const toast = useToast();
  const { id } = useParams();
  const [form, setForm] = React.useState<FormView>({});
  const [currentStep, setCurrentStep] = React.useState(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [completed, setCompleted] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    control,
    formState: { errors, isValid, isValidating, isSubmitSuccessful },
    watch,
    getFieldState,
  } = useForm({ mode: "all" });

  const onSubmit = async (data: Record<string, any>) => {
    console.log({ data });
    const formData: FormDataInput = {
      transactionId: id as string,
      records: JSON.stringify(data) as string,
    };

    setSaving(true);
    const response = await FormService.formsave(formData);
    setSaving(false);
    if (response.status && response.data) {
      window.location.href = `${response.data}`;
      return;
    }

    setCompleted(true);
  };

  const handleStepChange = () => {
    trigger();
    if (isValid || Object.keys(errors).length === 0) {
      setCurrentStep(currentStep + 1);
      return;
    }
    toast({
      title: "Validation Failed.",
      description: "Please fill all required field.",
      status: "error",
      duration: 9000,
      isClosable: true,
      position: "top",
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const form = (await (
          await FormService.formformtransactionTransactionId(id as string)
        ).data) as FormView;
        setForm(form);
        // @ts-ignore
        setCurrentStep(form?.steps[0]?.index ?? 0);
        console.log({ form });
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }

      if (localStorage.getItem(id as string)) {
        var values = JSON.parse(localStorage.getItem(id as string) as string);
        Object.entries(values).forEach(([key, value]) => setValue(key, value));
      }
      trigger();
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    const values = watch();
    if (Object.keys(values).length != 0)
      localStorage.setItem(id as string, JSON.stringify(values));
  }, [isValidating]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      toast({
        title: "Success",
        description: "Form submitted successfully.",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
      localStorage.clear();
    }
  }, [isSubmitSuccessful]);

  return (
    <>
      {loading && (
        <Center>
          <Box textAlign="center" py={10} px={6} width="80">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
            <Heading as="h2" size="xl" mt={6} mb={2}>
              Loading
            </Heading>
          </Box>
        </Center>
      )}
      {currentStep === 0 && !loading && (
        <Center>
          <Box textAlign="center" py={10} px={6} width="80">
            <WarningTwoIcon boxSize={"50px"} color={"orange.300"} />
            <Heading as="h2" size="xl" mt={6} mb={2}>
              Something went wrong
            </Heading>
            <Text color={"gray.500"}>
              Sorry, we are having issues loading this form at the moment please
              refresh the page after a few minutes or contact support. Thank you
            </Text>
          </Box>
        </Center>
      )}

      {completed && (
        <Center>
          <Box textAlign="center" py={10} px={6} width="80">
            <WarningTwoIcon boxSize={"50px"} color={"orange.300"} />
            <Heading as="h2" size="xl" mt={6} mb={2}>
              Form Submitted Succesfully
            </Heading>
            <Text color={"gray.500"}>
                Thanks you for taling the time to fill this form, we will get back to you shortly.
            </Text>
          </Box>
        </Center>
      )}

      {currentStep > 0 && !completed && (
        <Flex direction={"column"} w={"760px"} mx={"auto"} my={"6"}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex
              justifyContent={"center"}
              lineHeight={"8"}
              fontWeight={"semibold"}
              fontSize={"2xl"}
            >
              {form?.title}
            </Flex>
            <Flex
              mt={"2"}
              mb={"4"}
              justifyContent={"center"}
              lineHeight={"6"}
              color={"blackAlpha.500"}
              fontSize={"md"}
            >
              {form?.description}
            </Flex>
            <Flex
              direction={"column"}
              backgroundColor={"white"}
              px={"8"}
              borderRadius={"4"}
            >
              <Flex
                lineHeight={"7"}
                fontWeight={"semibold"}
                fontSize={"xl"}
                mt={"12"}
                mb={"9"}
              >
                Form Details
              </Flex>
              <Flex direction={"column"}>
                <SimpleGrid columns={2} spacingX={14} spacingY={6}>
                  {form.steps?.map((step, index) => {
                    return (
                      step.index == currentStep &&
                      step.fields?.map((field, index) => {
                        return (
                          // <>
                          <InputField
                            key={index}
                            register={register}
                            errors={errors}
                            control={control}
                            field={field}
                          />
                          
                        );
                      })
                    );
                  })}
                  {/* <PrimaryInput<FormInput>
                name={"title"}
                label={"Form Name"}
                placeholder={"Enter Form Name"}
                register={register}
                error={errors.title}
                defaultValue={""}
              />

              <PrimaryInput<FormInput>
                name={"description"}
                label={"Form Description"}
                placeholder={"Enter Form Description"}
                register={register}
                error={errors.description}
                defaultValue={""}
              /> */}
                </SimpleGrid>
              </Flex>
              <Box h={"1px"} w={"100%"} bgColor={"gray.100"} my={"8"} />
              <Flex
                direction={"row"}
                justifyContent={"space-between"}
                mb={"9"}
                mt={"10"}
              >
                <Button
                  bgColor={"blackAlpha.200"}
                  color={"blackAlpha.700"}
                  lineHeight={"5"}
                  fontSize={"md"}
                  fontWeight={"medium"}
                  display={"none"}
                >
                  Cancel
                </Button>
                {/* @ts-ignore */}
                {form?.steps[form.steps?.length - 1].index == currentStep ? (
                  <Button
                    bgColor={"blue.600"}
                    color={"white"}
                    lineHeight={"5"}
                    fontSize={"md"}
                    fontWeight={"medium"}
                    type="submit"
                  >
                    Submit
                  </Button>
                ) : (
                  <Button
                    bgColor={"blue.600"}
                    color={"white"}
                    lineHeight={"5"}
                    fontSize={"md"}
                    fontWeight={"medium"}
                    onClick={handleStepChange}
                    type="button"
                    display={"none"}
                  >
                    Next
                  </Button>
                )}
              </Flex>
            </Flex>
          </form>
          <Flex
                direction={"row"}
                justifyContent={"space-between"}
                mb={"9"}
                mt={"10"}
              >
                <Button
                  bgColor={"blackAlpha.200"}
                  color={"blackAlpha.700"}
                  lineHeight={"5"}
                  fontSize={"md"}
                  fontWeight={"medium"}
                    display={"none"}
                >
                  Cancel
                </Button>
                {/* @ts-ignore */}
                {form?.steps[form.steps?.length - 1].index == currentStep ? (
                  <Button
                    bgColor={"blue.600"}
                    color={"white"}
                    lineHeight={"5"}
                    fontSize={"md"}
                    fontWeight={"medium"}
                    type="submit"
                    display={"none"}
                    justifySelf={"flex-end"}
                  >
                    Submit
                  </Button>
                ) : (
                  <Button
                    bgColor={"blue.600"}
                    color={"white"}
                    lineHeight={"5"}
                    fontSize={"md"}
                    fontWeight={"medium"}
                    onClick={handleStepChange}
                    type="button"
                  >
                    Next
                  </Button>
                )}
              </Flex>
        </Flex>
      )}
    </>
  );
}
