import { Button, Flex, SimpleGrid } from '@chakra-ui/react';
import React, { FC } from 'react'
import { FieldError, FieldErrors, UseFormRegister } from 'react-hook-form';
import { FiTrash2 } from 'react-icons/fi';
import { FieldInput, FormInput, FormStepInput } from '../Services';
import { CreateStepAccordion } from './CreateStepAccordion';
import { PrimaryInput } from './CustomInputs/PrimaryInput';

interface IStepField {
    name: string;
    errors?: FieldErrors<FieldInput>;
    index: number;
    onRemove: () => void;
    step: FormStepInput;
    field: FieldInput
    register: UseFormRegister<FormInput>;
    fieldIndex: number;
}

export const StepField: FC<IStepField> = (
    ({ name, errors, index, fieldIndex, onRemove, register, step }) => (
        <CreateStepAccordion name={name} lighter={true}>
            <Flex direction={'column'}>
                <SimpleGrid columns={2} spacingX={14} spacingY={6} my={'4'}>
                <PrimaryInput<FormInput>
                        name={`steps.${index}.fields.${fieldIndex}.name`}
                        label={'Field Name'}
                        placeholder={'Enter Field Name'}
                        register={register}
                        error={errors?.name}
                        defaultValue={''}
                    />
                    <PrimaryInput<FormInput>
                        name={`steps.${index}.fields.${fieldIndex}.label`}
                        label={'Field Label'}
                        placeholder={'Enter Field Label'}
                        register={register}
                        error={errors?.label}
                        defaultValue={''}
                    />
                    <PrimaryInput<FormInput>
                        name={`steps.${index}.fields.${fieldIndex}.placeholder`}
                        label={'Field Placeholder'}
                        placeholder={'Enter Field Placeholder'}
                        register={register}
                        error={errors?.placeholder}
                        defaultValue={''}
                    />
                    <PrimaryInput<FormInput>
                        name={`steps.${index}.fields.${fieldIndex}.placeholder`}
                        label={'Field Placeholder'}
                        placeholder={'Enter Field Placeholder'}
                        register={register}
                        error={errors?.placeholder}
                        defaultValue={''}
                    />
                </SimpleGrid>
                <Flex justifyContent={'end'} mt={'6'}>
                    <Flex>
                        <Button variant="outline" onClick={onRemove}>
                            <Flex color={'red.300'} fontSize={14}>
                                Remove Step
                            </Flex>
                        </Button>
                    </Flex>
                </Flex>
            </Flex>
        </CreateStepAccordion>
    ))

