import { Box, FormLabel, HStack, Select, Text, Tooltip } from "@chakra-ui/react";
import { FieldError, Path, UseFormRegister } from "react-hook-form";
import { AiFillQuestionCircle } from "react-icons/ai";

interface FormInputProps<TFormValues extends Record<string, unknown>> {
  name: Path<TFormValues>;
  placeholder: string;
  register: UseFormRegister<TFormValues>;
  required?: boolean;
  validate?: any;
  error: FieldError | undefined;
  label?: string;
  fontSize?: string;
  options: string[];
  defaultValue?: any;
  tooltip: string;
}
export const PrimarySelect = <TFormValues extends Record<string, unknown>>({
  name,
  placeholder,
  register,
  required = false,
  validate = {},
  error,
  label = "",
  fontSize = "1rem",
  options,
  defaultValue,
  tooltip,
}: FormInputProps<TFormValues>) => {
  return (
    <Box>
      <HStack justifyContent={'space-between'}>
        <FormLabel color="brandBlack.100" htmlFor={name}>
          {label}
        </FormLabel>
        {tooltip && (<Tooltip hasArrow label={tooltip} fontSize="sm">
          <span style={{ display: "block" }}>
            <AiFillQuestionCircle />
          </span>
        </Tooltip>)}
      </HStack>

      <Select
        {...register(name, { required, ...validate })}
        w="full"
        border="1px solid grey"
        borderRadius="3"
        height="3rem"
        fontSize=".9rem"
        placeholder={placeholder}
        defaultValue={defaultValue}
        // icon={<Icons iconClass="fa-angle-right" />}
      >
        <option disabled>{placeholder}</option>
        {options.map((option, index) => {
          return (
            <option value={option} key={index}>
              {option}
            </option>
          );
        })}
      </Select>
      <Text fontSize=".7rem" color="red">
        {(error?.type === "required" && `${label} is required`) ||
          error?.message}
      </Text>
    </Box>
  );
};
