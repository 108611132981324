/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FieldTypeViewListStandardResponse } from '../models/FieldTypeViewListStandardResponse';
import type { FormDataInput } from '../models/FormDataInput';
import type { FormDataViewStandardResponse } from '../models/FormDataViewStandardResponse';
import type { FormInput } from '../models/FormInput';
import type { FormViewStandardResponse } from '../models/FormViewStandardResponse';
import type { StringStandardResponse } from '../models/StringStandardResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class FormService {

    /**
     * @param requestBody
     * @returns FormViewStandardResponse Success
     * @throws ApiError
     */
    public static formnew(
        requestBody?: FormInput,
    ): CancelablePromise<FormViewStandardResponse> {
        return __request({
            method: 'POST',
            path: `/api/Form/new`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns FieldTypeViewListStandardResponse Success
     * @throws ApiError
     */
    public static formfieldtypes(): CancelablePromise<FieldTypeViewListStandardResponse> {
        return __request({
            method: 'GET',
            path: `/api/Form/fieldtypes`,
        });
    }

    /**
     * @param formId
     * @param redirectUrl
     * @returns any Success
     * @throws ApiError
     */
    public static formformFormId(
        formId: string,
        redirectUrl?: string,
    ): CancelablePromise<any> {
        return __request({
            method: 'GET',
            path: `/api/Form/form/${formId}`,
            query: {
                'RedirectUrl': redirectUrl,
            },
        });
    }

    /**
     * @param transactionId
     * @returns FormViewStandardResponse Success
     * @throws ApiError
     */
    public static formformtransactionTransactionId(
        transactionId: string,
    ): CancelablePromise<FormViewStandardResponse> {
        return __request({
            method: 'GET',
            path: `/api/Form/form/transaction/${transactionId}`,
        });
    }

    /**
     * @param requestBody
     * @returns StringStandardResponse Success
     * @throws ApiError
     */
    public static formsave(
        requestBody?: FormDataInput,
    ): CancelablePromise<StringStandardResponse> {
        return __request({
            method: 'POST',
            path: `/api/Form/save`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param transactionId
     * @returns FormDataViewStandardResponse Success
     * @throws ApiError
     */
    public static formformdataTransactionId(
        transactionId: string,
    ): CancelablePromise<FormDataViewStandardResponse> {
        return __request({
            method: 'GET',
            path: `/api/Form/formdata/${transactionId}`,
        });
    }

}